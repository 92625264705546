import React from 'react';
import { Text, Box, Flex, Card, Button, Image, FormControl, FormLabel, Input, CardBody, Textarea } from '@chakra-ui/react';
import Nav from './Nav';
import Background from '../assets/sky.png'
import Footer from './Footer';

const Download = () => {
  const blueButtonStyle = {
    color: 'beige',
    backgroundColor: '#525D98',
    fontSize: ['xl', 'xl', '2xl'],
    w: "7em",
    h: "2.5em",
    border: "2px solid #0000000D",
    fontWeight: '500',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#606BAB',
      color: '#beige',
    },
    boxShadow: "0px 7px #455087",
    fontWeight: "400"
  }
  const beigeButtonStyle = {
    color: '#685F5A',
    backgroundColor: '#EAE9E0',
    fontSize: 'lg',
    px: "1em",
    h: "2.5em",
    border: "2px solid #0000000D",
    fontWeight: '500',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#F8F7EC',
      color: '#685F5A',
    },
    boxShadow: "0px 7px #CCCBC2"
  }
  const textStyle = {
    color: 'beige',
    textShadow: '0 1px 1px #00000033'
  }

  return (
  <div>
    <Box backgroundColor="#ffffff00">
      <Box position="absolute" h="100%" overflow="hidden" zIndex={-3} w="100%">
        <Image src={Background} pos="relative" zIndex={-2} top={0} left={0} w="100%"/>
      </Box>
      <Box pt={5} h="100vh">
        <Nav />
        <Flex maxW={["100%", 650]} m="auto" p={10} flexDir="column" gridGap={4} pt={[5, "8em"]}>
          <Text m="auto" sx={textStyle} fontSize="xl">Download a demo of LeafyLearn</Text>
          <Flex m="auto" gridGap={4}> 
          <a href="https://www.dropbox.com/scl/fi/hq80y4r0phvord303tnn6/LeafyLearnMAC.zip?rlkey=9c9w17idn6pgw2t8hf2a630u5&dl=1">
            <Button sx={blueButtonStyle} m="auto">MacOS</Button>
          </a>
          <a href="https://www.dropbox.com/scl/fi/7i0zbe1j61ozzkfobf19c/LeafyLearnWINDOWS.zip?rlkey=f4txoxhug6d8okqrfwc2zjvc1&dl=1">
            <Button sx={blueButtonStyle} m="auto">Intel</Button>
          </a>
          </Flex>
          <Text m="auto" sx={textStyle} pt="5" w={["100%", "80%"]}>  
            Note: Because this is a demo, we haven't gotten the app verified with Apple. After opening LeafyLearn, you'll see the message "LeafyLearn can't be opened because Apple can't check it for malicious software". Open the game with Control + Click, then click "Open Anyways." 
            You can also go to Systems Preferences → General → Open LeafyLearn.
          </Text>
          <Text m="auto" sx={textStyle} pt="5" >
            Leave feedback or record bugs:
          </Text>
          <Flex m="auto">
            <a href="https://forms.gle/MFd5HQ2yijEwr1hg9" target="_blank">
            <Button sx={beigeButtonStyle} m="auto">
              Feedback Form
            </Button>
            </a>
          </Flex>
        </Flex>
      </Box>
    </Box>
  </div>
  )
}

export default Download;