import React from 'react';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import { useMediaQuery } from 'react-responsive';

const Nav = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  if (isDesktop) {
    return (
      <div>
        <DesktopNav />
      </div>
    );
  } else {
    return (
      <div>
        <MobileNav />
      </div>
    );
  }
}

export default Nav;