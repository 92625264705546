import React from 'react';
import { useLayoutEffect } from 'react';
import { Text, Box, Flex, UnorderedList, ListItem, Image } from '@chakra-ui/react';
import Nav from './Nav';
import Background from '../assets/sky.png'
import Ground from '../assets/about-ground2.png'
import PinkFlower from '../assets/flower-pink.png'
import BlueFlower from '../assets/flower-blue.png'
import YellowFlower from '../assets/flower-yellow.png'
import Footer from './Footer';

const About = () => {
  const textStyle = {
    color: 'beige',
    textShadow: '0 1px 1px #00000033'
  }
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
  <div>
      <Box position="absolute" h="100%" overflow="hidden" zIndex={-3} w="100%">
        <Image src={Background} pos="relative" zIndex={-2} top={0} left={0} w="100%"/>
        <Image src={Ground} pos="absolute" top="4.5em" left={0} zIndex={-1} w="100%"/>
        <Box backgroundColor="#40855C" h="100%" zIndex={-5} position="relative"/>
      </Box>
      <Box pb={10} pt={5} backgroundColor="#ffffff00" zIndex={2}>
        <Nav/>
        <Box maxW={650} m="auto" p={[5, 10]} mt="15vw" backgroundColor="#ffffff00">
          <Flex flexDir='column' gridGap={6}>
            <Text sx={textStyle} fontSize='3xl' m="auto" fontWeight={500}>About</Text>
            <Image src={PinkFlower} w={7} m="auto"/>
            <Box>
              <Text fontSize='2xl' sx={textStyle} fontWeight='500' mb={2}>What is LeafyLearn?</Text>
              <Text sx={textStyle} fontSize={['md', 'lg']}>
              The learning curve to study coding can seem daunting and complex. LeafyLearn aims to break barriers in coding education by teaching programming to anyone and everyone in a fun, interactive, approachable, and aesthetic game. 
              </Text>
            </Box>
            <Image src={BlueFlower} w={9} m="auto"/>
            <Box>
              <Text fontSize='2xl' sx={textStyle} fontWeight='500' mb={2}>Features</Text>
              <UnorderedList sx={textStyle} fontSize={['md', 'lg']}>
                <ListItem>Explore the island environment</ListItem>
                <ListItem>See the island come back to life after completing lessons</ListItem>
                <ListItem>Interact with code panel to solve code puzzles and learn programming concepts</ListItem>
                <ListItem>Customize your character's avatar</ListItem>
                <ListItem>Emote through your character</ListItem>
              </UnorderedList>
            </Box>
            <Image src={YellowFlower} w={9} m="auto"/>
            <Box>
              <Text fontSize='2xl' sx={textStyle} fontWeight='500' mb={2}>Programming Curriculum</Text>
              <UnorderedList sx={textStyle} fontSize={['md', 'lg']}>
                <ListItem>Print statements</ListItem>
                <ListItem>Variables</ListItem>
                <ListItem>Functions</ListItem>
                <ListItem>Parameters</ListItem>
                <ListItem>Return statements</ListItem>
                <ListItem>If-statements</ListItem>
                <ListItem>Operators</ListItem>
                <ListItem>While loops</ListItem>
                <ListItem>Conditional</ListItem>
              </UnorderedList>
            </Box>
            <Image src={PinkFlower} w={7} m="auto"/>
            <Box>
              <Text fontSize='2xl' sx={textStyle} fontWeight='500' mb={2}>What programming language does LeafyLearn use?</Text>
              <Text sx={textStyle} fontSize={['md', 'lg']}>
              Each programming language has its complications that often trip up newcomers. 
              To reduce the confusion that programming syntax often causes for beginners, LeafyLearn uses psuedo-code that incoporates elements from Python and Java. 
              The goal is to help beginners focus on coding concepts first to prepare them for the small details that come with learing the syntax of programming languages.
              </Text>
            </Box>
            <Image src={BlueFlower} w={8} m="auto"/>
            <Box>
              <Text fontSize='2xl' sx={textStyle} fontWeight='500' mb={2}>Who created LeafyLearn?</Text>
              <Text sx={textStyle} fontSize={['md', 'lg']}>
              LeafyLearn was created by a team of Dartmouth Computer Science students who are interested in making computer science education more accessible. 
              </Text>
            </Box>
            <Image src={YellowFlower} w={9} m="auto"/>
            <Box>
              <Text fontSize='2xl' sx={textStyle} fontWeight='500' mb={2}>How was LeafyLearn created?</Text>
              <Text sx={textStyle} fontSize={['md', 'lg']}>
              The game mechanics were developed in Unity, the UI/UX design was created in Figma, and the 3D digital assets were modeled in Maya.
              </Text>
            </Box>
            <Footer />
          </Flex>
        </Box>


      </Box>

    {/* </Box> */}

  </div>
  
  )
}

export default About;