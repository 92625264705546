import React from 'react';
import { Flex, Button, Input, Text } from '@chakra-ui/react';

const Newsletter = () => {
  const textStyle = {
    color: 'beige',
    textShadow: '0 1px 1px #00000033',
    fontSize: ['md', 'md', 'xl'],
    textAlign: 'center'
  }

  const blueButtonStyle = {
    color: 'beige',
    backgroundColor: '#525D98',
    fontSize: 'xl',
    py: "1.1em",
    border: "2px solid #0000000D",
    fontWeight: '400',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#606BAB',
      color: '#beige',
    },
    }

  return (
    <Flex flexDir="column" gridGap={3} p={4}>
      <Text m="auto" sx={textStyle}>Join the mailing list for updates</Text>
    <Flex 
      flexDir="row"
      gridGap={2}
      >
        <Input 
          placeholder="Your email (you@example.com)" 
          backgroundColor="beige"
          size="lg"
          w="16em"
          color="#685F5A"
        />
        <Button sx={blueButtonStyle}>Subscribe</Button>
    </Flex>
    </Flex>
  )
}

export default Newsletter;