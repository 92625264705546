import { extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/fredoka';

// 2. Call `extendTheme` and pass your custom values
const Theme = extendTheme({
  fonts: {
    body: 'Fredoka Variable, sans-serif',
  },
  colors: {
    beige: '#FEFDF4',
    green: '#57C683',
  }
});

export default Theme;
