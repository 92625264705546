import React from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Button } from '@chakra-ui/react';

const DesktopNav = () => {
  const buttonStyle = {
    color: 'beige',
    backgroundColor: 'green',
    fontSize: 'xl',
    border: "2px solid #0000000D",
    fontWeight: '500',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#5ECF8C',
      color: 'beige',
    },
    boxShadow: "0px 7px #369C5F"
  }

  return (
    <Flex 
      flexDir="row" 
      justifyContent="center" 
      gridGap={24} 
      color="beige" 
      m='auto' 
      p={2} 
      fontSize='xl'
      alignItems='center'
      w="100%"
      >
      <NavLink to="/">Home</NavLink>
      <NavLink to="/about">About</NavLink>
      {/* <NavLink to="/contact">Contact</NavLink> */}
      <NavLink to="/download"><Button sx={buttonStyle}>Download</Button></NavLink>
    </Flex>
  )
}

export default DesktopNav;