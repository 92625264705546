import React from 'react';
import { Box, Image, Text, Flex, Button, Divider } from '@chakra-ui/react';
import Background from '../assets/sky.png'
import Ground from '../assets/ground3.png'
import Title from '../assets/title.png'
import '../index.css'
import Puzzle from '../assets/code puzzle.png'
import Explore from '../assets/explore.png'
import Nav from './Nav';
import { Link, NavLink } from 'react-router-dom';
import Footer from './Footer';
import Newsletter from './Newsletter';
import YellowFlower from '../assets/flower-yellow.png'

const Home = () => {
  const textStyle = {
    color: 'beige',
    textShadow: '0 1px 1px #00000033',
    fontSize: ['md', 'md', 'xl'],
    textAlign: 'center'
  }

  const buttonStyle = {
    color: 'beige',
    backgroundColor: 'green',
    fontSize: ['xl', 'xl', '2xl'],
    w: "7em",
    h: "2.5em",
    border: "2px solid #0000000D",
    fontWeight: '500',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#5ECF8C',
      color: 'beige',
    },
    boxShadow: "0px 7px #369C5F"
  }

  const cardStyle = {
    backgroundColor: "#EAE9E0",
    borderRadius: [10, 12],
    justifyContent: "center",
    alignItems: "center",
    border: ["2.5px solid #0000000D", "3px solid #0000000D"]
  }

  const cardTextStyle = {
    color: "#685F5A",
    fontSize: ['0.9em','md', 'md', 'lg'],
    px: [2, 3, 3, 5],
    py: [1.5, 2, 2, 2.5]
  }

  const beigeButtonStyle = {
    color: '#685F5A',
    backgroundColor: '#EAE9E0',
    fontSize: ['xl', 'xl', '2xl'],
    w: "7em",
    h: "2.5em",
    border: "2px solid #0000000D",
    fontWeight: '500',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#F8F7EC',
      color: '#685F5A',
    },
    boxShadow: "0px 7px #CCCBC2"
  }

  return (
    <Box backgroundColor="#ffffff00" w="100%">
      <Box position="absolute" h="100%" overflow="hidden" zIndex={-3} w="100%">
        <Image src={Background} pos="relative" zIndex={-2} top={0} left={0} w="100%"/>
        <Image src={Ground} pos="absolute" top="38em" left={0} zIndex={-1} w="100%"/>
        <Box backgroundColor="#40855C" h="100%" zIndex={-5} position="relative"/>
      </Box>
      <Box pb={10} pt={5} backgroundColor="#ffffff00" zIndex={2}>
        <Nav/>
        <Box maxW={650} m="auto" p={[5, 10, 10]} backgroundColor="#ffffff00">
          <Image src={Title} w={['100%', '100%', '80%']} m="auto" pb="1em"/>
          <Flex gridGap={8} flexDir="column" alignItems="center">
            <Flex gridGap={4} flexDir="column">
              <Text sx={textStyle}>
              A sudden meteor shower has destroyed the code that powers the island of Bytopia...
              </Text>
              <Text sx={textStyle}>
              Bring the island back to life by solving code puzzles and learning programming fundamentals with your island guardian, Musho!
              </Text>
            </Flex>
              <iframe width="100%" height="300vw" src="https://www.youtube.com/embed/1w8fdWV_WUE?si=nJDDHp8TzI8A8cG1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"></iframe>
            <NavLink to="/download">
            <Button sx={buttonStyle} m={4}>
              Download
            </Button>
            </NavLink>
            <Newsletter />
            <Flex flexDir="column" gridGap={20} mt={10}>
              <Flex flexDir="column" alignItems="center" gridGap={4}>
                <Text sx={textStyle}>Learn programming topics such as:</Text>
                <Flex flexDir="row" gridGap={[2, 3]}>
                  <Flex sx={cardStyle}>
                    <Text sx={cardTextStyle}>Functions</Text>
                  </Flex>
                  <Flex sx={cardStyle}>
                    <Text sx={cardTextStyle}>If-statements</Text>
                  </Flex>
                  <Flex sx={cardStyle}>
                  <Text sx={cardTextStyle}>While Loops</Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexDir="column" alignItems="center" gridGap={4}>
                <Text sx={textStyle} m="auto">
                  Interact with a code puzzle panel to see your program run live
                </Text>
                <Image src={Puzzle} borderRadius={10}/>
              </Flex>

              <Flex flexDir="column" alignItems="center" gridGap={4}>
                <Text sx={textStyle}>
                Explore the island to see your progress
                </Text>
                <Image src={Explore} borderRadius={10}/>
              </Flex>
            </Flex>
            <NavLink to="/about">
              <Button sx={beigeButtonStyle} m={4}>Learn More</Button>
            </NavLink>
            
            <Flex flexDir="column" fontSize="md" color="beige" textShadow='0 1px 1px #00000033' alignItems='center' gridGap={0.5}>
              <Text fontSize="xl" pb={2} fontWeight={500}>Team</Text>
              <Text>Claire Draeger</Text>
              <Text>Jason Pak</Text>
              <Text>Annie Qiu</Text>
              <Text>Jemely Robles</Text>
              <Text>Leina Sato</Text>
              <Text>Grace Wang</Text>
            </Flex>
            <Image src={YellowFlower} w={9} m="auto"/>
            <Flex flexDir="column" fontSize="md" color="beige" textShadow='0 1px 1px #00000033' alignItems='center' gridGap={0.5}>
              <Text fontSize="xl" pb={2} fontWeight={500}>Contact Us</Text>
              <Link to="mailto:leafylearngame@gmail.com"><Text>leafylearngame@gmail.com</Text></Link>
            </Flex>
          </Flex>
        </Box>
        <Footer />

      </Box>
    </Box>
  )
}
export default Home;
