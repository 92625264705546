import { Drawer, DrawerOverlay, Button, Text, MenuButton, Flex, useDisclosure, DrawerContent, DrawerCloseButton, IconButton, Menu, ListItem } from "@chakra-ui/react";
import React from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
const MobileNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()  
  const buttonStyle = {
    color: 'beige',
    backgroundColor: 'green',
    fontSize: '2xl',
    border: "2px solid #0000000D",
    fontWeight: '450',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#5ECF8C',
      color: 'beige',
    },
    boxShadow: "0px 7px #369C5F",
    py: "0.9em",
    px: "0.75em",
    mt: "0.5em"
  }

  return (
    <div>
      <Flex flexDir="row-reverse" pr={2}>
        <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Menu'
          icon={<HamburgerIcon />}
          variant='clear'
          color="beige"
          ref={btnRef} onClick={onOpen} 
        />
        </Menu>
        <Drawer 
          isOpen={isOpen}
          placement='top'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent backgroundColor="beige" color="#685F5A">
            <DrawerCloseButton/>
            <Flex flexDir="column" gridGap={2} p={12} fontSize="3xl" alignItems="center">
              <NavLink to="/">
                <Text>Home</Text>
              </NavLink>
              <NavLink to="/about">
                <Text>About</Text>
              </NavLink>
              {/* <NavLink to="/contact">
                <Text>Contact</Text>
              </NavLink> */}
              <NavLink to="/download"><Button sx={buttonStyle}>Download</Button></NavLink>
            </Flex>
          </DrawerContent>
        </Drawer>
      </Flex>
    </div>
  )
}

export default MobileNav;