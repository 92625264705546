import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const Footer = () => {
  const textStyle = {
    color: 'beige',
    textShadow: '0 1px 1px #00000033',
    fontSize: 'lg'
  }

  return (
    <Flex m={10}>
      <Text sx={textStyle} m="auto">
        LeafyLearn 2024
      </Text>
    </Flex>
  )
}

export default Footer;