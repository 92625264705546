import React from 'react';
import { Text, Box, Flex, Card, Button, Image, FormControl, FormLabel, Input, CardBody, Textarea } from '@chakra-ui/react';
import Nav from './Nav';
import Background from '../assets/sky.png'
import Footer from './Footer';

const Contact = () => {
  const textStyle = {
    color: '#685F5A'
  }

  const buttonStyle = {
    color: 'beige',
    backgroundColor: 'green',
    fontSize: 'xl',
    border: "2px solid #0000000D",
    fontWeight: '500',
    borderRadius: '0.5em',
    _hover: {
      backgroundColor: '#5ECF8C',
      color: 'beige',
    },
    boxShadow: "0px 7px #369C5F"
  }

  return (
  <div>
    <Box backgroundColor="#ffffff00">
      <Box position="absolute" h="100%" overflow="hidden" zIndex={-3} w="100%">
        <Image src={Background} pos="relative" zIndex={-2} top={0} left={0} w="100%"/>
      </Box>
      <Box pt={5} h="100vh">
        <Nav />
        <Box maxW={650} m="auto" p={10} position="relative">
          <Card p={3}>
            <CardBody>
              <FormControl isRequired>
                <Flex flexDir="column" gridGap={2}>
                <Text sx={textStyle} fontSize="xl" fontWeight="500">Contact</Text>
                <Box>
                  <FormLabel sx={textStyle} fontWeight={400}>Name</FormLabel>
                  <Input sx={textStyle} />
                </Box>
                <Box>
                  <FormLabel sx={textStyle} fontWeight={400}>Email</FormLabel>
                  <Input sx={textStyle}/>
                </Box>
                <Box>
                  <FormLabel sx={textStyle} fontWeight={400}>Message</FormLabel>
                  <Textarea sx={textStyle} h="12em"/>
                </Box>
                <Box m="auto">
                  <Button mt={2} sx={buttonStyle} type='submit'>Submit</Button>
                </Box>
                </Flex>
              </FormControl>
            </CardBody>
          </Card>
        </Box>
      </Box>
    </Box>
  </div>
  
  )
}

export default Contact;