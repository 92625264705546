import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import Home from "./components/Home";
import { ChakraProvider } from '@chakra-ui/react';
import Theme from './components/Theme';
import About from './components/About';
import Contact from './components/Contact';
import Download from './components/Download';


function App() {
  return (
    <ChakraProvider theme={Theme}>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} /> 
            <Route path="/about" element={<About />} /> 
            <Route path="/contact" element={<Contact />} /> 
            <Route path="/download" element={<Download />} /> 
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
